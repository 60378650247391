import { IconDefinition } from '@ant-design/icons-angular';

const AppDragOutline: IconDefinition = {
  name: 'app-drag',
  theme: 'outline',
  icon: `<svg viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="10" height="18" rx="3.5" stroke="#F0F0F0" fill="none"/>
  <path d="M3.75 4.625a0.875 0.875 0 1 1 0 1.75a0.875 0.875 0 1 1 0 -1.75z M3.75 9a0.875 0.875 0 1 1 0 1.75a0.875 0.875 0 1 1 0 -1.75z M3.75 13.375a0.875 0.875 0 1 1 0 1.75a0.875 0.875 0 1 1 0 -1.75z M7.25 4.625a0.875 0.875 0 1 1 0 1.75a0.875 0.875 0 1 1 0 -1.75z M7.25 9a0.875 0.875 0 1 1 0 1.75a0.875 0.875 0 1 1 0 -1.75z M7.25 13.375a0.875 0.875 0 1 1 0 1.75a0.875 0.875 0 1 1 0 -1.75z" fill="currentColor"/>
  </svg>
  `,
};

const AppDashboardOutline: IconDefinition = {
  name: 'app-dashboard',
  theme: 'outline',
  icon: `<svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.813 4.813V10.438h3.375V4.813H4.813ZM3.125 4.813c0-.932.756-1.688 1.688-1.688h3.375c.932 0 1.687.756 1.687 1.688v5.625c0 .932-.755 1.687-1.687 1.687H4.813c-.932 0-1.688-.755-1.688-1.687V4.813ZM13.813 11.563v5.625h3.375v-5.625h-3.375ZM12.125 11.563c0-.932.756-1.688 1.688-1.688h3.375c.932 0 1.687.756 1.687 1.688v5.625c0 .932-.755 1.687-1.687 1.687h-3.375c-.932 0-1.688-.755-1.688-1.687v-5.625ZM8.188 14.938H4.813v2.25h3.375v-2.25ZM4.813 13.25h3.375c.932 0 1.687.756 1.687 1.688v2.25c0 .932-.755 1.687-1.687 1.687H4.813c-.932 0-1.688-.755-1.688-1.687v-2.25c0-.932.756-1.688 1.688-1.688ZM13.813 4.813v2.25h3.375v-2.25h-3.375ZM12.125 4.813c0-.932.756-1.688 1.688-1.688h3.375c.932 0 1.687.756 1.687 1.688v2.25c0 .932-.755 1.687-1.687 1.687h-3.375c-.932 0-1.688-.755-1.688-1.687v-2.25Z"/>
</svg>`,
};

const AppMoreOutline: IconDefinition = {
  name: 'app-more',
  theme: 'outline',
  icon: `<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.5 12a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm0-5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9 3.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
</svg>
`,
};

const AppFilterOutline: IconDefinition = {
  name: 'app-filter',
  theme: 'outline',
  icon: `<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M0 2.01523C0 1.38633 0.511328 0.875 1.14023 0.875L12.8598 0.875C13.4887 0.875 14 1.38633 14 2.01523C14 2.27773 13.9098 2.53203 13.743 2.73438L9.1875 8.32617V12.2418C9.1875 12.7285 8.79102 13.125 8.3043 13.125C8.10469 13.125 7.91055 13.0566 7.75469 12.9336L5.22539 10.9266C4.96289 10.7188 4.8125 10.4043 4.8125 10.0707V8.32617L0.257031 2.73438C0.0902344 2.53203 0 2.27773 0 2.01523ZM1.50391 2.1875L5.97734 7.67812C6.07305 7.7957 6.125 7.94063 6.125 8.09375V9.96406L7.875 11.3531V8.09375C7.875 7.94336 7.92695 7.7957 8.02266 7.67812L12.4961 2.1875L1.50391 2.1875Z" fill="#0958D9"/>
  </svg>`,
};

const AppNarrowOutline: IconDefinition = {
  name: 'app-narrow',
  theme: 'outline',
  icon: `<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.6 2.85V12.75H16.2C16.4475 12.75 16.65 12.5475 16.65 12.3V3.3C16.65 3.0525 16.4475 2.85 16.2 2.85H12.6ZM11.25 2.85H6.75V12.75H11.25V2.85ZM5.4 12.75V2.85H1.8C1.5525 2.85 1.35 3.0525 1.35 3.3V12.3C1.35 12.5475 1.5525 12.75 1.8 12.75H5.4ZM0 3.3C0 2.30719 0.807187 1.5 1.8 1.5H16.2C17.1928 1.5 18 2.30719 18 3.3V12.3C18 13.2928 17.1928 14.1 16.2 14.1H1.8C0.807187 14.1 0 13.2928 0 12.3V3.3Z" fill="#8C8C8C"/>
</svg>`,
};

const AppWideOutline: IconDefinition = {
  name: 'app-wide',
  theme: 'outline',
  icon: `<svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1011_41085)">
<path d="M15.75 13.2001C15.75 13.4476 15.4125 13.6501 15 13.6501H3C2.5875 13.6501 2.25 13.4476 2.25 13.2001V2.4001C2.25 2.1526 2.5875 1.9501 3 1.9501H15C15.4125 1.9501 15.75 2.1526 15.75 2.4001V13.2001ZM18 2.4001C18 1.40729 16.6547 0.600098 15 0.600098H3C1.34531 0.600098 0 1.40729 0 2.4001V13.2001C0 14.1929 1.34531 15.0001 3 15.0001H15C16.6547 15.0001 18 14.1929 18 13.2001V2.4001Z" fill="#8C8C8C"/>
</g>
<defs>
<clipPath id="clip0_1011_41085">
<rect width="18" height="14.4" fill="white" transform="translate(0 0.600098)"/>
</clipPath>
</defs>
</svg>
`,
};

export const CUSTOM_ICONS: IconDefinition[] = [
  AppDashboardOutline,
  AppDragOutline,
  AppFilterOutline,
  AppMoreOutline,
  AppNarrowOutline,
  AppWideOutline,
];
