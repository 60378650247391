import { APP_INITIALIZER, ApplicationConfig, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { Router, withRouterConfig, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { routes } from './app.routes';
import { provideNzIcons } from './icons-provider';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { environment } from '@env/environment';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { LoggingInterceptorService } from 'src/app/interceptors/logging.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { InitThemeService } from '@services/init-theme.service';
import { ThemeSkinService } from '@services/theme-skin.service';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import * as Sentry from '@sentry/angular';
import { RedirectionService } from '@services/redirection.service';
import { RequestInterceptor } from 'src/app/interceptors/request.interceptor';
import { ResponseInterceptor } from 'src/app/interceptors/response.interceptor';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { GOOGLE_AUTH_CLIENT_ID } from '@services/auth/google-auth.service';

registerLocaleData(en);

if (environment.production) {
  enableProdMode();
}

export function InitThemeServiceFactory(initThemeService: InitThemeService) {
  return async (): Promise<void> => await initThemeService.initTheme();
}

export function redirectRoute(redirectionService: RedirectionService): () => Promise<void> {
  return (): Promise<void> =>
    new Promise((resolve) => {
      redirectionService.handleRedirection();
      resolve();
    });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      // withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'disabled',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      }),
    ),
    RedirectionService,
    {
      provide: APP_INITIALIZER,
      useFactory: redirectRoute,
      deps: [RedirectionService],
      multi: true,
    },
    provideNzIcons(),
    provideNzI18n(en_US),
    importProvidersFrom(FormsModule),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    // provideAnimationsAsync(),
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: InitThemeServiceFactory,
      deps: [InitThemeService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themeService: ThemeSkinService) => () => {
        return themeService.loadTheme();
      },
      deps: [ThemeSkinService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideHttpClient(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: GOOGLE_AUTH_CLIENT_ID,
      useValue: environment.googleAuthClientKey,
    },
    CustomCurrencyPipe,
    CurrencyPipe,
    NzModalService,
    NzDrawerService,
  ],
};
