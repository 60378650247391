import { Inject, Injectable, InjectionToken } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { SafeAny } from 'src/app/types/generic.types';

// #TODO fix "google" types

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type TokenResponse = google.accounts.oauth2.TokenResponse;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type TokenClient = google.accounts.oauth2.TokenClient;

export const GOOGLE_AUTH_CLIENT_ID = new InjectionToken<string>('GOOGLE_AUTH_CLIENT_ID');

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  private readonly tokenResponse$ = new Subject<TokenResponse>();
  private ready: Promise<void>;

  private client: TokenClient;

  /**
   * Must include script in body:
   *    <script src="https://accounts.google.com/gsi/client" async defer></script>
   */
  constructor(@Inject(GOOGLE_AUTH_CLIENT_ID) private readonly clientId: string) {
    this.init();
  }

  async login() {
    await this.ready;

    console.log('Google Auth | Requesting access token');
    this.client.requestAccessToken();

    console.log('Google Auth | waiting for token response');
    return firstValueFrom(this.tokenResponse$);
  }

  private init() {
    this.ready = new Promise((resolve) => {
      (window as SafeAny).onGoogleLibraryLoad = () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.client = google.accounts.oauth2.initTokenClient({
          client_id: this.clientId,
          scope: 'profile email',
          prompt: 'consent',
          callback: (tokenResponse) => {
            this.tokenResponse$.next(tokenResponse);
          },
        });

        resolve();
      };
    });
  }
}
