import { Injectable } from '@angular/core';
import { AuthUser, LoginPayload, SocialLoginResponse } from '@services/api/auth-api.types';
import { tap } from 'rxjs/internal/operators/tap';
import { StorageKey, WindowService } from '@services/window.service';
import { Store } from '@services/store';
import { AuthApiService } from '@services/api/auth-api.service';
import { lastValueFrom, Observable, switchMap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { RegistrationFollowUpFormParams } from '@services/auth/auth-service.types';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly authApiService: AuthApiService,
    private readonly windowService: WindowService,
    private readonly store: Store,
  ) {}

  login(data: LoginPayload): Observable<AuthUser> {
    return this.authApiService.login(data).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
      switchMap(() => this.getProfile()),
    );
  }

  loginWithGoogle(authToken: string): Observable<SocialLoginResponse> {
    return this.authApiService.loginWithGoogle(authToken).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
    );
  }

  refreshToken() {
    const refreshToken = this.windowService.getStorage(StorageKey.RefreshToken);
    return this.authApiService.refreshToken(refreshToken).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.windowService.setStorage(StorageKey.AccessToken, accessToken);
        this.windowService.setStorage(StorageKey.RefreshToken, refreshToken);
      }),
    );
  }

  async logout(fromAllDevices: boolean = false) {
    const refreshToken = this.windowService.getStorage(StorageKey.RefreshToken);
    if (refreshToken) {
      // first, request a logout the existing token(s)
      void lastValueFrom(this.authApiService.logout(refreshToken, fromAllDevices));
    }

    // then, clean up the local storage and the store
    this.windowService.removeStorage(StorageKey.AccessToken);
    this.windowService.removeStorage(StorageKey.RefreshToken);
    this.store.removeUser();
  }

  getProfile(checkAuthStatus: boolean = false) {
    return this.authApiService.getProfile(checkAuthStatus).pipe(
      tap((authUser) => {
        this.store.setUser(authUser);
      }),
      catchError((error) => {
        if (checkAuthStatus) {
          this.store.removeUser();
        }

        return throwError(() => error);
      }),
    );
  }

  getRegistrationFollowUpFormUrl(params: RegistrationFollowUpFormParams): string {
    // #TODO populate params.country
    params.country = 'ro';
    const paramString = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    return `${environment.registrationFollowUpFormUrl}#${paramString}`;
  }
}
