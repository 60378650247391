import { Injectable } from '@angular/core';
import { AuthUser, LoginPayload, RegisterPayload, SocialLoginResponse } from '@services/api/auth-api.types';
import { HttpClient } from '@angular/common/http';
import { CustomHttpHeaderName, HttpRequestScope } from 'src/app/types/http.types';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  constructor(private readonly http: HttpClient) {}

  register(data: RegisterPayload) {
    return this.http.post<void>('auth/signup', data);
  }

  login(data: LoginPayload) {
    return this.http.post<{ accessToken: string; refreshToken: string }>('auth/signin', data);
  }

  loginWithGoogle(authToken: string) {
    return this.http.post<SocialLoginResponse>('auth/social/google/signin', { authToken });
  }

  refreshToken(refreshToken: string) {
    return this.http.post<{ accessToken: string; refreshToken: string }>(
      'auth/refresh-token',
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          [CustomHttpHeaderName.REQUEST_SCOPE]: HttpRequestScope.REFRESH_TOKEN,
        },
      },
    );
  }

  logout(refreshToken: string, fromAllDevices: boolean = false) {
    return this.http.post(
      'auth/logout',
      { fromAllDevices },
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          [CustomHttpHeaderName.REQUEST_SCOPE]: HttpRequestScope.LOGOUT,
        },
      },
    );
  }

  getProfile(checkAuthStatus: boolean = false) {
    const headers = checkAuthStatus ? { [CustomHttpHeaderName.REQUEST_SCOPE]: HttpRequestScope.CHECK_AUTH_STATUS } : {};
    return this.http.get<AuthUser>('auth/profile', { headers });
  }
}
