export const MobileBottomMenuMinHeight = 60;
export const MobileBottomMenuItemHeight = 39;

export enum CommonRoutes {
  LoginPage = '/login',
  DashboardLandingPage = '/',
  MasterDashboardLandingPage = '/master',
}

export const UploadCareConf = {
  cdnUrl: 'https://ucarecdn.com/',
  uploadUrl: 'https://upload.uploadcare.com/base/?jsonerrors=1',
};
