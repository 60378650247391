import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { AuthUser } from '@services/api/auth-api.types';
import { Location } from 'src/app/models/location.model';
import { PageTitle } from '@services/store.types';
import { PageCtaButton } from '@services/store.types';

@Injectable({
  providedIn: 'root',
})
export class Store {
  private readonly user$ = new BehaviorSubject<AuthUser | null | undefined>(undefined);
  private readonly currentLocation$ = new BehaviorSubject<Location | null>(null);
  private readonly pageTitle$ = new BehaviorSubject<PageTitle | undefined>(undefined);
  private readonly pageCtaButton$ = new BehaviorSubject<PageCtaButton | undefined>(undefined);
  private readonly pageMobileCtaButton$ = new BehaviorSubject<PageCtaButton | undefined>(undefined);
  private readonly initialRouteAfterLogin$ = new BehaviorSubject<string | undefined>(undefined);

  setUser(user: AuthUser) {
    this.user$.next(user);
  }

  removeUser() {
    this.user$.next(null);
  }

  watchUser(): Observable<AuthUser | null> {
    return this.user$.asObservable().pipe(filter((v) => v !== undefined));
  }

  getUser(): Observable<AuthUser | null> {
    return this.watchUser().pipe(first());
  }

  setCurrentLocation(location: Location) {
    this.currentLocation$.next(location);
  }

  removeCurrentLocation() {
    this.currentLocation$.next(null);
  }

  watchCurrentLocation(): Observable<Location | null> {
    return this.currentLocation$.asObservable();
  }

  getCurrentLocation(): Observable<Location | null> {
    return this.watchCurrentLocation().pipe(first());
  }

  setPageTitle(newTitle: string | PageTitle) {
    const title = typeof newTitle === 'string' ? { sm: newTitle, md: newTitle } : newTitle;
    this.pageTitle$.next(title);
  }

  setCtaButton(ctaButton: PageCtaButton) {
    this.pageCtaButton$.next(ctaButton);
  }

  setMobileCtaButton(ctaButton: PageCtaButton) {
    this.pageMobileCtaButton$.next(ctaButton);
  }

  watchPageSettings() {
    return combineLatest([this.pageTitle$, this.pageCtaButton$, this.pageMobileCtaButton$]).pipe(
      map(([title, ctaButton, mobileCtaButton]) => ({
        title,
        ctaButton,
        mobileCtaButton,
      })),
    );
  }

  setInitialRouteAfterLogin(route: string) {
    this.initialRouteAfterLogin$.next(route);
  }

  removeInitialRouteAfterLogin() {
    this.initialRouteAfterLogin$.next(undefined);
  }

  getInitialRouteAfterLogin(): Observable<string | undefined> {
    return this.initialRouteAfterLogin$.asObservable().pipe(first());
  }
}
