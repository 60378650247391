import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { SubscribedComponent } from './lib/subscribed.component';
import { PreloaderService } from '@services/preloader.service';
import { SpinService } from '@services/spin.service';
import { filter, takeUntil } from 'rxjs';
import { NzSafeAny } from 'ng-zorro-antd/core/types';
import { AuthService } from '@services/auth/auth.service';
import { Store } from '@services/store';

@Component({
  selector: 'app-root',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, NzLayoutModule, NzSpinModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent extends SubscribedComponent implements OnInit, AfterViewInit {
  title = 'Appointments Taking App';
  loading$ = this.spinService.getCurrentGlobalSpinStore();

  constructor(
    private readonly router: Router,
    private readonly spinService: SpinService,
    private readonly preloader: PreloaderService,
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setGlobalSpin();
    this.scrollToTop();

    // check if user is (still) authenticated
    this.authService.getProfile(true).subscribe();
  }

  setGlobalSpin(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: NzSafeAny) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.spinService.setCurrentGlobalSpinStore(false);
      });
  }

  scrollToTop(): void {
    this.router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        window.scrollTo(0, 0);
      });
  }

  ngAfterViewInit(): void {
    this.preloader.removePreLoader();
  }
}
