const host = 'https://api.timetailor.com';

export const environment = {
  production: true,
  apiUrl: `${host}/`,
  version: 'bf7424177b79a93f2d66df5b82e0bf1a2f72c87b', // Placeholder for version
  name: 'production',
  uploadCarePublicKey: 'dcc918afd43d13903024',
  registrationFollowUpFormUrl: 'https://form.typeform.com/to/mpGKglvl',
  googleAuthClientKey: '891482607059-483hcpird9s85m18ndc4is031ls5voo5.apps.googleusercontent.com',
};
